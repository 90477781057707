<h2 class="content-block">Liste des articles</h2>

<div style="margin: 0 0 1.5em 1.5em;">
  <dx-button icon="menu" (onClick)="onToggleClassificationDisplay()" hint="Afficher / Cacher les classifications" style="float: left; margin-right: 12px;"></dx-button>
  <dx-button text="Créer un article" [disabled]="!isModificationAutorisee" routerLink="/article/nouveau" style="margin-right: 12px;"></dx-button>
  <dx-button text="Template" style="margin-right: 12px;" (onClick)="downloadTemplate()"
    [disabled]="recuperationTemplateEncours"
  >
      <div style="display: flex; align-items: center;">
        <dx-load-indicator [visible]="recuperationTemplateEncours" height="20" width="20"></dx-load-indicator>
        <span class="dx-button-text" [ngStyle]="{'margin-left': recuperationTemplateEncours ? '10px' : 'auto'}">
          Modèle CSV
        </span>
      </div>
  </dx-button>
  <dx-button text="Importer" (onClick)="this.showImportCsvPopup = true" [disabled]="!isModificationAutorisee"></dx-button>
</div>

<div style="padding: 0 12px 12px 12px; display: flex; flex-wrap: wrap;">
  <div style="overflow: auto; margin-bottom: 12px; width: 450px; margin-right: 12px;" *ngIf="showClassifications">
    <dx-scroll-view>
      <dx-tree-list
        #treeList
        [dataSource]="classificationValues"
        parentIdExpr="parentId"
        [rootValue]="null"
        [showRowLines]="true"
        [showBorders]="true"
        [columnAutoWidth]="true"
        [allowColumnResizing]="true"
        (onSelectionChanged)="onClassificationValueSelectionChanged($event)"
        [width]="450"
      >
        <!-- Toolbar -->
        <dxo-toolbar>
          <dxi-item location="before">
            <div *dxTemplate style="display: flex; align-items: center;">
              <dx-switch [(value)]="isSelectionRecursive" [elementAttr]="{class: 'mr-12'}" (onValueChanged)="onToggleIsSelectionRecursive()"></dx-switch>
              <span>Sélection auto. des enfants</span>
            </div>
          </dxi-item>
          <dxi-item>
            <div *dxTemplate>
              <dx-button icon="deletetable" hint="Réinitialiser les filtres" (onClick)="clearTreeListFilters()"></dx-button>
            </div>
          </dxi-item>
          <dxi-item name="searchPanel"></dxi-item>
        </dxo-toolbar>

        <!-- Options -->
        <dxo-remote-operations [filtering]="true" [sorting]="true" [paging]="true" [grouping]="true"></dxo-remote-operations>
        <dxo-scrolling mode="infinite"></dxo-scrolling>
        <dxo-selection mode="multiple" [allowSelectAll]="false" [recursive]="isSelectionRecursive"></dxo-selection>
        <dxo-search-panel [visible]="true"></dxo-search-panel>
        <dxo-row-dragging [onAdd]="onAddArticleIntoClassification" [showDragIcons]="false" [allowDropInsideItem]="true" group="articleList" [allowReordering]="false"></dxo-row-dragging>

        <!-- Colonnes -->
        <dxi-column dataField="codeBo" caption="Code" [width]="230"></dxi-column>
        <dxi-column dataField="intitule" caption="Intitulé" [width]="180"></dxi-column>
      </dx-tree-list>
    </dx-scroll-view>
  </div>

  <app-article-list-datagrid
    [initComponent]="initDataGrid"
    grilleStorageKey="grille_article_globale"
    [showStockSociete]="true"
    [showStockSite]="true"
    [marginTop]="130"
    [typeSelection]="showClassifications ? 'multiple' : null"
    [enableRowDragging]="showClassifications"
    [classificationValueIds]="classificationValueIds"
    [ngStyle]="{'width': showClassifications ? 'calc(100% - 462px)' : 'auto'}"
    (sansClassification)="clearTreeListFilters()"
    (classificationTypeChanged)="setClassificationValues()"
  ></app-article-list-datagrid>
</div>

<!-- Popup : Info import CSV -->
<dx-popup
  [width]="500"
  [height]="450"
  title = "Importer des articles via fichier .csv"
  [(visible)]="showImportCsvPopup"
  [showCloseButton]="true"
>
  <div *dxTemplate="let data of 'content'">
    <div [hidden]="showLoader">
      <dx-file-uploader
        [multiple]="false"
        [allowedFileExtensions]="['.csv']"
        uploadMode="useForm"
        selectButtonText="Importer un fichier .csv"
        readyToUploadMessage=""
        labelText="ou Glisser/Déposer le fichier"
        [(value)]="fichierCsv"
        (click)="resetImportResultMsg()"
      ></dx-file-uploader>
      <dx-button style="margin-right: 10px;" (click)="cancelImportCsv()" text="Annuler"></dx-button>
      <dx-button (click)="importDataFromCsv()" text="Importer" [disabled]="!fichierCsv[0]?.name.endsWith('.csv')"></dx-button>
      <div *ngIf="importCsvResultMsg" style="margin-top: 20px;">
        <p>
          <b *ngIf="!importHasFailed">Résultat</b>
          <b *ngIf="importHasFailed" style="color: red;">Une erreur est survenue lors de l'importation CSV</b>
        </p>
        <p>{{importCsvResultMsg}}</p>
        <p>{{importCsvResultMsg2}}</p>
      </div>
    </div>
    <div [hidden]="!showLoader" style="display: flex; justify-content: center; align-items: center;">
      <dx-load-indicator [visible]="showLoader" height="150" width="150"></dx-load-indicator>
    </div>
  </div>
</dx-popup>