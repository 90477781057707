import { SocieteAvecPermissionsPortailClientDto, TypeUtilisateurRuunui } from '@lexi-clients/lexi';

export interface Navigation {
    icon: string;
    text: string;
    path: string | null;
    activity: string;
    enabled: boolean;
    expanded?: boolean;
    items: Navigation[];
}

const donneesBaseNavigation = (enableTransporteurs: boolean): Navigation => {
    const donneesBase =  {
        text: 'Données de base',
        path: '',
        icon: 'folder',
        activity: '',
        enabled: true,
        items: [
            { text: 'Clients', path: '/clients', icon: 'user', activity: '', enabled: true, items: [] },
            { text: 'Articles', path: '/articles', icon: 'product', activity: '', enabled: true, items: [] },
            { text: 'Fournisseurs', path: '/fournisseurs', icon: 'user', activity: '', enabled: true, items: [] },
        ]
    };

    if (enableTransporteurs) {
        donneesBase.items.push(
            { text: 'Transporteurs', path: '/transporteurs', icon: 'icon ion-md-boat', activity: '', enabled: true, items: [] }
        );
    }

    return donneesBase;
}

export interface NavigationTop {
    id: number;
    title: string;
    navigation: Navigation[];
    enabled: boolean;
}

export enum NavigationCollaborateurMenuId {
    vente = 0,
    logistique = 2,
    sav = 3,
    douane = 4,
    fretLocal = 5,
    pointDeVente = 6,
    kpi = 7,
    administration = 8,
    comptabilite = 9
}

export class Parametrage {
    currentUserType: TypeUtilisateurRuunui;
    currentUserCanGererTransporteurs: boolean;
    /** true si la société en cours gère du stock */
    currentSocieteManageStock: boolean;
}

export const navigationCollaborateur = (parametrage: Parametrage): Array<NavigationTop> => {
    const enableTransporteurs: boolean = parametrage.currentUserCanGererTransporteurs && parametrage.currentUserType == TypeUtilisateurRuunui.chargeur;

    return [
        {
            id: NavigationCollaborateurMenuId.vente,
            title: 'Vente',
            enabled: false,
            navigation: [
                { text: 'Site', path: '/site-en-cours', icon: 'home', activity: '', enabled: true, items: [] },
                { text: 'Clients', path: 'clients', icon: 'user', activity: '', enabled: true, items: [] },
                { text: 'Articles', path: 'articles', icon: 'product', enabled: true, activity: '', items: [] },
                { text: 'Pièces', path: null, icon: 'file', enabled: true, activity: '', items: [
                    { text: 'Pièces', path: 'vente/pieces', icon: 'file', enabled: true, activity: '', items: [] },
                    { text: 'Règlements', path: 'vente/reglements', icon: 'file', enabled: true, activity: '', items: [] },
                ]},
                { text: 'Stock', path: null, icon: 'mediumiconslayout', enabled: parametrage.currentSocieteManageStock, activity: '', items: [
                    { text: 'Etat', path: '/visualisation-stock', icon: 'mediumiconslayout', enabled: parametrage.currentSocieteManageStock, activity: '', items: [] },
                    { text: 'Mouvements', path: '/visualisation-des-mouvements', icon: 'icon ion-md-swap', enabled: parametrage.currentSocieteManageStock, activity: '', items: [] },
                    { text: 'Bons', path: '/visualisation-des-bons', icon: 'textdocument', enabled: parametrage.currentSocieteManageStock, activity: '', items: [] },
                ] },
                { text: 'Clients fidélisés', path: 'vente/clients-fidelises', icon: 'group', enabled: true, activity: '', items: [] },
                { text: 'Editions', path: 'vente/editions', icon: 'paste', enabled: true, activity: '', items: [] },
                { text: 'Statistiques', path: 'vente/statistiques-site', icon: 'paste', enabled: true, activity: '', items: [] },
                { text: 'Caisse', path: null, icon: 'icon ion-md-desktop', enabled: true, activity: '', items: [
                    { text: 'Contrôle', path: 'vente/controle-de-periode', icon: 'clock', enabled: true, activity: '', items: [] },
                    { text: 'Gestion des caisses', path: 'vente/caisses', icon: 'product', enabled: true, activity: '', items: [] },
                    { text: 'Raccourcis', path: 'vente/raccourcis', icon: 'movetofolder', enabled: true, activity: '', items: [] },
                ] },
                { text: 'Synchronisation Bo', path: 'vente/synchronisation-back-office', icon: 'download', enabled: true, activity: '', items: [] }
            ]
        },
        {
            id: NavigationCollaborateurMenuId.logistique,
            title: 'Logistique',
            enabled: false,
            navigation: navigationLogistique(parametrage, enableTransporteurs)
        },
        {
            id: NavigationCollaborateurMenuId.sav,
            title: 'Sav',
            enabled: false,
            navigation: [
                // { text: 'Commun', path: '', icon: 'folder', activity: '', enabled: true, items: [
                //     { text: 'Clients', path: '/sav/clients', icon: 'folder', activity: '', enabled: true, items: [] },
                //     { text: 'Produits', path: '/sav/articles', icon: 'folder', activity: '', enabled: true, items: [] },
                // ] },
                { text: 'Dossiers', path: '/sav/dossiers', icon: 'activefolder', activity: '', enabled: true, items: [] },
                // { text: 'Demandes', path: '/sav/demandes', icon: 'user', activity: '', enabled: true, items: [] },
                // { text: 'Interventions', path: '/sav/interventions', icon: 'user', enabled: true, activity: '', items: [] },
                // { text: 'Parc', path: '/sav/parcs', icon: 'user', activity: '', enabled: true, items: [] },
                // { text: 'Contrats', path: '/sav/contrats', icon: 'user', enabled: true, activity: '', items: [] },
                // { text: 'Paramètres', path: '', icon: 'folder', enabled: true, activity: '', items: [
                //     { text: 'Type de contrats', path: '/sav/contrat-types', enabled: true, icon: 'user', activity: '', items: [] },
                //     { text: 'Type d\'évènements', path: '/sav/evenement-types', enabled: true, icon: 'user', activity: '', items: [] },
                //     { text: 'Flux', path: '/sav/flux', icon: 'user', enabled: true, activity: '', items: [] },
                //     { text: 'Caractéristiques', path: '/sav/caracteristiques', icon: 'user', enabled: true, activity: '', items: [] },
                // ] },
                donneesBaseNavigation(enableTransporteurs)
            ]
        },
        {
            id: NavigationCollaborateurMenuId.douane,
            title: 'Douane',
            enabled: false,
            navigation: [ {
                text: 'Déclarations',
                path: '/douane/declarations',
                icon: '',
                activity: '',
                enabled: true,
                items: []
            },
            donneesBaseNavigation(enableTransporteurs)
        ]
        },
        {
            id: NavigationCollaborateurMenuId.fretLocal,
            title: 'Fret local',
            enabled: false,
            navigation: [
                { text: 'Connaissements', path: '/fret-local/connaissements', icon: 'icon ion-md-boat', enabled: parametrage.currentUserType == TypeUtilisateurRuunui.chargeur, activity: '', items: [] },
                { text: 'Connaissements',  path: null, icon: 'icon ion-md-boat', activity: '', enabled: parametrage.currentUserType == TypeUtilisateurRuunui.armateur, items: [
                    { text: 'Liste', path: '/fret-local/connaissements-liste', icon: 'bulletlist', enabled: parametrage.currentUserType == TypeUtilisateurRuunui.armateur, activity: '', items: [] },
                    { text: 'Nouveau', path: '/fret-local/nouveau', icon: 'add', enabled: parametrage.currentUserType == TypeUtilisateurRuunui.armateur, activity: '', items: [] },
                ]},
                { text: 'Demandes chargeurs', path: '/fret-local/demandes-chargeurs', icon: 'icon ion-md-filing', enabled: parametrage.currentUserType == TypeUtilisateurRuunui.armateur, activity: '', items: [] },
                { text: 'Voyages', path: '/fret-local/voyages', icon: 'icon ion-md-clipboard', enabled: parametrage.currentUserType == TypeUtilisateurRuunui.armateur, activity: '', items: [] },
                { text: 'Secteurs géographiques', path: '/fret-local/secteurs-geographique', icon: 'globe', enabled: parametrage.currentUserType == TypeUtilisateurRuunui.chargeur, activity: '', items: [] },
                { text: 'Nomenclatures', path: '/fret-local/nomenclatures', icon: 'icon ion-md-document', enabled: parametrage.currentUserType == TypeUtilisateurRuunui.armateur, activity: '', items: [] },
                donneesBaseNavigation(enableTransporteurs)
            ]
        },
        {
            id: NavigationCollaborateurMenuId.kpi,
            title: 'KPI',
            enabled: false,
            navigation: [
                { text: 'Tableaux de bord', path: '/kpi/dashboards', icon: 'chart', enabled: true, activity: '', items: [] },
                { text: 'Gérer les tableaux de bord', path: '/kpi/dashboards/admin', icon: 'preferences', enabled: true, activity: '', items: [] },
                donneesBaseNavigation(enableTransporteurs)
            ]
        },
        {
            id: NavigationCollaborateurMenuId.comptabilite,
            title: 'Comptabilité',
            enabled: false,
            navigation: [
                { text: 'Plan comptable', path: '/comptabilite/comptes-generaux', icon: 'chart', enabled: true, activity: '', items: [] },
                { text: 'Interface comptable', path: '/comptabilite/interface-comptable', icon: 'icon ion-md-desktop', enabled: true, activity: '', items: []},
            ]
        }
    ];
}

export const navigationPartenaire = (societe: SocieteAvecPermissionsPortailClientDto): Array<NavigationTop> => {
    return [
        {
            id: 0,
            title: '',
            enabled: false,
            navigation: [
                { text: 'Profil', path: '/portail-client/information-profil', icon: 'user', activity: '', enabled: true, items: [] },
                { text: 'Factures', path: '/portail-client/factures', icon: 'file', enabled: societe?.portailClientAffichageFactures ?? false, activity: '', items: [] },
                { text: 'Demandes', path: '/portail-client/demandes', icon: 'bulletlist', enabled: societe?.portailClientAffichageDemandes ?? false, activity: '', items: [] },
                { text: 'Interventions', path: '/portail-client/interventions', icon: 'icon ion-md-construct', enabled: societe?.portailClientAffichageActivites ?? false, activity: '', items: [] },
                // { text: 'Dossiers', path: '/portail-client/contrats', icon: 'icon ion-md-document', enabled: societe?.portailClientAffichageDossiers ?? false, activity: '', items: [] },
                { text: 'Connaissements', path: '/portail-client/connaissements', icon: 'icon ion-md-boat', enabled: societe?.portailClientAffichageConnaissements ?? false, activity: '', items: [] },
                { text: 'Catalogue', path: '/portail-client/articles', icon: 'icon ion-md-cart', enabled: societe?.portailClientAffichageArticles ?? false, activity: '', items: [] }
            ]
        }
    ];
};

const navigationLogistique = (parametrage: Parametrage, enableTransporteurs: boolean): Navigation[] => {
    const stock: Navigation = { text: 'Stock',  path: null, icon: 'folder', activity: '', enabled: true, items: [
        { text: 'Etat du stock', path: '/visualisation-stock', icon: 'mediumiconslayout', enabled: true, activity: '', items: [] },
        { text: 'Numéros de série', path: '/logistique/registres-numeros-serie', icon: 'startswith', enabled: true, activity: '', items: [] },
        { text: 'Mouvements', path: '/visualisation-des-mouvements', icon: 'icon ion-md-swap', enabled: true, activity: '', items: [] },
    ]};

    const activites: Navigation = { text: 'Activités',  path: null, icon: 'folder', activity: '', enabled: true, 
        items: parametrage.currentSocieteManageStock 
            ? [
                { text: 'Documents', path: '/logistique/documents', icon: 'textdocument', enabled: true, activity: '', items: [] },
                { text: 'Bons', path: '/visualisation-des-bons', icon: 'textdocument', enabled: true, activity: '', items: [] },
            ] : [
                { text: 'Réceptions', path: '/logistique/receptions', icon: 'icon ion-md-paper', enabled: true, activity: '', items: [] },
                { text: 'Inventaires', path: '/logistique/inventaires', icon: 'icon ion-md-clipboard', enabled: true, activity: '', items: [] },
                { text: 'Préparations', path: '/logistique/preparations', icon: 'box', enabled: true, activity: '', items: [] },
            ]
        };
    const parametres: Navigation = 
        { text: 'Paramètres',  path: null, icon: 'folder', activity: '', enabled: true, expanded: false, items: [
            { text: 'Back Office', path: '/logistique/traitements', icon: 'preferences', enabled: true, activity: '', items: [] },
            { text: 'Gestion des terminaux', path: '/logistique/terminaux', icon: 'cart', enabled: true, activity: '', items: [] },
            { text: 'Lieux de stockage', path: '/logistique/lieux-de-stockage', icon: 'verticalalignbottom', enabled: parametrage.currentSocieteManageStock, activity: '', items: [] },
            { text: 'Référencement', path: '/logistique/code-barres', icon: 'icon ion-md-barcode', enabled: true, activity: '', items: [] },
        ]};
    
    const navigations: Navigation[] = [];
    
    if (parametrage.currentSocieteManageStock) {
        navigations.push(stock);
    }
    navigations.push(activites);
    navigations.push(parametres);
    navigations.push(donneesBaseNavigation(enableTransporteurs))
    
    return navigations;
};

