import { Component, Input, OnDestroy } from '@angular/core';
import { EditionTemplateListDto, EditionTemplatesLexiClient, EditionsLexiClient, ObjectType } from '@lexi-clients/lexi';
import { DownloadService } from 'lexi-angular/src/app/services/download.service';
import { Subscription, lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-objecttype-edition-possible-list',
  templateUrl: './objecttype-edition-possible-list.component.html',
  styleUrls: ['./objecttype-edition-possible-list.component.scss'],
})
export class ObjecttypeEditionPossibleListComponent implements OnDestroy {

  private _objectType: ObjectType;
  get objectType(): ObjectType {
    return this._objectType;
  }
  @Input() set objectType(value: ObjectType) {
    if (value) {
      this._objectType = value;
      this.loadData();
    }
  }

  private _objectId: number;
  get objectId(): number {
    return this._objectId;
  }
  @Input() set objectId(value: number) {
    if (value) {
      this._objectId = value;
    }
  }
  
  private subscriptions = new Subscription();

  editionsPossible: EditionTemplateListDto[];
  
  constructor(private editionTemplatesLexiClient: EditionTemplatesLexiClient,
    private editionsLexiClient: EditionsLexiClient,
    private downloadService: DownloadService) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async loadData() {
    this.editionsPossible = await lastValueFrom(this.editionTemplatesLexiClient.getByObjectType(this.objectType));
  }

  async onDownloadEdition(selectedItem) {
    const selectedEditionTemplate = selectedItem?.itemData;
    const response = await lastValueFrom(this.editionsLexiClient.getByEditionTemplateAndObjectId(selectedEditionTemplate.id, this.objectId, 'response'));
    this.downloadService.directDownloadFile(response, 'pdf');
  }
}
