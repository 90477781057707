import { Component, NgModule, Output, Input, EventEmitter, ViewChild, ElementRef, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { DxTreeViewModule, DxTreeViewComponent } from 'devextreme-angular/ui/tree-view';
import { NavigationTop, navigationCollaborateur, navigationPartenaire, Parametrage, Navigation, NavigationCollaborateurMenuId } from '../../../models/navigation-top';
import * as events from 'devextreme/events';
import { ModuleService } from '../../services/module.service';
import { AuthService } from 'lexi-angular/src/app/settings/auth.service';
import { ParametrageRevatuaLexiClient, TypeUtilisateur } from '@lexi-clients/lexi';
import { AppInfoService } from '../../services';
import { FooterModule } from '../footer/footer.module';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { SocieteService } from 'lexi-angular/src/app/services/societe.service';
import Scrollable from 'devextreme/ui/scroll_view/ui.scrollable';
import { CommonModule } from '@angular/common';
import { Permissions } from '@lexi-clients/lexi';
import { InternationalisationPipe } from 'lexi-angular/src/app/modules/shared/pipes/internationalisation.pipe';
import { Subscription, filter } from 'rxjs';

@Component({
  selector: 'app-side-navigation-menu',
  templateUrl: './side-navigation-menu.component.html',
  styleUrls: ['./side-navigation-menu.component.scss']
})
export class SideNavigationMenuComponent implements AfterViewInit, OnDestroy {
  @ViewChild(DxTreeViewComponent, { static: true })
  menu: DxTreeViewComponent;

  @Output()
  selectedItemChanged = new EventEmitter<string>();

  @Output()
  openMenu = new EventEmitter<any>();

  private _selectedItem: string;
  @Input()
  set selectedItem(value: string) {
    this._selectedItem = value;
    if (!this.menu.instance) {
      return;
    }

    this.menu.instance.selectItem(value);
  }

  items: Navigation[] = [];
  private flattenItems: Navigation[] = [];

  private _compactMode = false;
  @Input()
  get compactMode() {
    return this._compactMode;
  }
  set compactMode(val) {
    this._compactMode = val;

    if (!this.menu.instance) {
      return;
    }

    if (val) {
      this.menu.instance.collapseAll();
    } else {
      this.menu.instance.expandItem(this._selectedItem);
    }
  }

  isPartenaire = false;
  navPrincipal: Array<NavigationTop> =[];

  navigationOrganisation: Navigation = {
    text: 'Organisation',
    path: null,
    icon: 'activefolder',
    activity: '',
    enabled: true,
    items: [
      { text: 'Société', path: '/administration/infos-societe', icon: 'globe', activity: '', enabled: true, items: [] },
      { text: 'Sites', path: '/administration/sites', icon: 'home', activity: '', enabled: true, items: [] },
      { text: 'Équipes', path: '/administration/equipes', icon: 'group', activity: '', enabled: true, items: [] },
      { text: 'Lieux de stockage', path: '/logistique/lieux-de-stockage', icon: 'verticalalignbottom', enabled: true, activity: '', items: [] },
    ]
  };

  navigationClient: Navigation = {
    text: 'Clients / Fournisseurs',
    path: null,
    icon: 'activefolder',
    activity: '',
    enabled: true,
    items: [
      { text: 'Clients', path: 'clients', icon: 'user', activity: '', enabled: true, items: [] },
      { text: 'Fournisseurs', path: 'fournisseurs', icon: 'user', activity: '', enabled: true, items: [] },
      { text: 'Partenaires', path: 'partenaires', icon: 'user', activity: '', enabled: true, items: [] },
      { text: 'Classifications', path: '/administration/classifications/clients', icon: 'bookmark', activity: '', enabled: true, items: [] },
      { text: 'Formes Juridiques', path: '/administration/formes-juridiques', icon: 'bookmark', activity: '', enabled: true, items: [] },
      { text: 'Profils Partenaires', path: '/administration/profils-partenaires', icon: 'bookmark', activity: '', enabled: true, items: [] },
    ]
  };


  navigationArticle: Navigation = {
    text: 'Articles',
    path: null,
    icon: 'activefolder',
    activity: '',
    enabled: true,
    items: [
      { text: 'Articles', path: 'articles', icon: 'product', enabled: true, activity: '', items: [] },
      { text: 'Classifications', path: '/administration/classifications/articles', icon: 'bookmark', activity: '', enabled: true, items: [] },
      { text: 'Groupes de taxes', path: '/administration/groupes-taxe', icon: 'percent', activity: '', enabled: true, items: [] },
      { text: 'Taxes', path: '/administration/taxes', icon: 'percent', activity: '', enabled: true, items: [] },
      { text: 'Unités', path: '/administration/unites', icon: 'underline', activity: '', enabled: true, items: [] },
    ]
  };

  navigationGenerale: Navigation = {
    text: 'Paramètres généraux',
    path: null,
    icon: 'activefolder',
    activity: '',
    enabled: true,
    items: [
      { text: 'Process', path: '/administration/gestion-des-process', icon: 'sorted', activity: '', enabled: true, items: [] },
      { text: 'Stratégies de comptage', path: '/administration/strategies-comptage', icon: 'textdocument', activity: '', enabled: true, items: [] },
      { text: 'Types de mouvement', path: '/administration/types-de-mouvement', icon: 'sorted', activity: '', enabled: true, items: [] },
      { text: 'Types d\'évènement', path: '/administration/gestion-evenement-type', icon: 'textdocument', enabled: true, activity: '', items: [] },
      { text: 'Caractéristiques', path: '/administration/caracteristiques', icon: 'cart', enabled: true, activity: '', items: [] },
      { text: 'Types de caractéristique', path: '/administration/types-caracteristique', icon: 'cart', enabled: true, activity: '', items: [] },
      { text: 'Codes avantages', path: '/administration/codes-avantage', icon: 'cart', enabled: true, activity: '', items: [] },
    ]
  };

  navigationTerminaux: Navigation = {
    text: 'Terminaux',
    path: null,
    icon: 'activefolder',
    activity: '',
    enabled: true,
    items: [
      { text: 'Terminaux', path: '/administration/terminaux', icon: 'cart', enabled: true, activity: '', items: [] },
      { text: 'BackOffices', path: '/administration/backoffices', icon: 'textdocument', enabled: true, activity: '', items: [] },
    ]
  };

  navigationVente: Navigation = {
    text: 'Vente',
    path: null,
    icon: 'activefolder',
    activity: '',
    enabled: true,
    items: [
      { text: 'Modes de règlement', path: '/administration/modes-de-reglement', icon: 'tableproperties', activity: '', enabled: true, items: [] },
      { text: 'Monnaies', path: '/administration/monnaies', icon: 'money', activity: '', enabled: true, items: [] },
      { text: 'Motifs annulation', path: '/administration/motifs-annulation', icon: 'return', activity: '', enabled: true, items: [] },
      { text: 'Motifs retour', path: '/administration/motifs-retour', icon: 'return', activity: '', enabled: true, items: [] },
      { text: 'Nature des pièces', path: '/administration/gestion-magasin-piece-nature', icon: 'tags', activity: '', enabled: true, items: [] },
      { text: 'Opérations commerciales', path: '/administration/operations-commerciales', icon: 'chart', activity: '', enabled: true, items: [] },
      { text: 'Types de remise', path: '/administration/types-de-remise', icon: 'filter', activity: '', enabled: true, items: [] },
      { text: 'Prix', path: '/administration/prix', icon: 'money', activity: '', enabled: true, items: [] },
      { text: 'Tarifications', path: '/administration/tarifications', icon: 'percent', activity: '', enabled: true, items: [] },
      { text: 'Processus de facturation', path: '/administration/processus-facturations', icon: 'textdocument', activity: '', enabled: true, items: [] },
      { text: 'Bons d\'achat', path: '/administration/bons-achat', icon: 'gift', activity: '', enabled: true, items: [] },
    ]
  };

  navigationSecurityConfig: Navigation = {
    text: 'Sécurité',
    path: null,
    icon: 'activefolder',
    activity: '',
    enabled: true,
    items: [
      { text: 'Utilisateurs', path: '/securite/utilisateurs', icon: 'user', activity: '', enabled: true, items: [] },
      { text: 'Rôles', path: '/securite/roles', icon: 'group', activity: 'group', enabled: true, items: [] },
      { text: 'Affecter rôles', path: '/securite/affecter-roles', icon: 'home', activity: '', enabled: true, items: [] },
    ]
  };

  navigationEditionConfig: Navigation = {
    text: 'Éditions',
    path: null,
    icon: 'activefolder',
    activity: '',
    enabled: true,
    items: [
      { text: 'Éditions', path: '/editions', icon: 'background', activity: '', enabled: true, items: [] },
      { text: 'Édition Mappings', path: '/editions/mappings', icon: 'hierarchy', activity: '', enabled: true, items: [] },
      { text: 'Process édition', path: '/administration/gestion-process-edition', icon: 'hierarchy', activity: '', enabled: true, items: [] },
      { text: 'Affecter process édition', path: '/administration/affecter-process-edition', icon: 'hierarchy', activity: '', enabled: true, items: [] },
    ]
  };

  navigationTechniqueConfig: Navigation = {
    text: 'Suivi',
    path: null,
    icon: 'activefolder',
    activity: '',
    enabled: true,
    items: [
      { text: 'Audit', path: '/administration/audit', icon: 'clock', activity: '', enabled: true, items: [] },
      { text: 'Logs', path: '/administration/logs', icon: 'comment', activity: '', enabled: true, items: [] },
      { text: 'Activité utilisateur', path: '/administration/activite-utilisateur', icon: 'chart', activity: '', enabled: true, items: [] },
      { text: 'Message', path: '/administration/message', icon: 'email', activity: '', enabled: true, items: [] },
    ]
  };

  navigationAdministrationConfig = [
    this.navigationOrganisation,
    this.navigationArticle,
    this.navigationClient,
    this.navigationEditionConfig,
    this.navigationVente,
    this.navigationGenerale,
    this.navigationTerminaux,
    this.navigationSecurityConfig,
    this.navigationTechniqueConfig,
    { text: 'Portail client', path: '/administration/portail-client', icon: 'hierarchy', activity: '', enabled: true, items: [] },
  ];

  private subscription = new Subscription();

  constructor (
    private elementRef: ElementRef,
    private moduleService: ModuleService,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    public appInfo: AppInfoService,
    private readonly customSocieteService: SocieteService,
    private readonly parametrageRevatuaLexiClient: ParametrageRevatuaLexiClient,
    private readonly internationalisationPipe: InternationalisationPipe,
    private readonly router: Router
  ) {
    this.moduleService.currentModule.asObservable().subscribe(async x => {
      if (x == NavigationCollaborateurMenuId.administration) {
        this.loadAdministrationMenu();
      }
      else {
        await this.loadModule(x)
      }
    });
  }

  private loadAdministrationMenu() {

    // config
    if(this.authService.user?.isRoot) {
      this.items = this.navigationAdministrationConfig;
    }
    else {
      // Si l'utilisateur n'est pas root, 
      // on filtre les onglets accessibles dans le menu
      this.items = this.setNavigationAdministrationConfig();
    }

    for(const item of this.items) {
      item.text = this.internationalisationPipe.transform(item.text);
      for(const subitem of item.items) {
        subitem.text = this.internationalisationPipe.transform(subitem.text);
      }
    }

    this.flattenItems = this.getFlattenItems(this.items);
  }

  async loadModule(moduleId: number) {
    if(this.authService.user?.typeUtilisateur === TypeUtilisateur.collaborateur.toLowerCase()) {
      const parametrage: Parametrage = {
        currentUserType: await this.authService.getStoredUserTypeRuunui(),
        currentUserCanGererTransporteurs: this.authService.isGrantedWith(Permissions.canGererTransporteurs),
        currentSocieteManageStock: this.authService.currentSociete != null && this.authService.currentSociete.gestionStock
      }
      this.navPrincipal = navigationCollaborateur(parametrage);
    }

    else {
      this.isPartenaire = true;
      let societe = this.customSocieteService.getSocietePortailClient();
      if(!societe) {
       await this.customSocieteService.setSocietePortailClient();
        societe = this.customSocieteService.getSocietePortailClient();
      }
      this.navPrincipal = navigationPartenaire(societe);
    }

    const nav = this.navPrincipal.find(i => i.id === moduleId);

    if (nav) {
      this.items = nav.navigation.map((item) => {
        if (item && item.path && !(/^\//.test(item.path))) {
          item.path = `/${item.path}`;
        }
        return { ...item, visible: item.enabled, expanded: !this._compactMode && item.expanded !== false,  };
      });

      for(const i of this.items) {
        i.text = this.internationalisationPipe.transform(i.text);
      }

      this.flattenItems = this.getFlattenItems(this.items);
    }

    this.cdr.markForCheck();
  }

  onItemClick(event) {
    this.selectedItemChanged.emit(event);
  }

  onContentReady(e: any) {
    const scrollWidget = Scrollable.getInstance(e.element.querySelector('.dx-scrollable'));
    if(scrollWidget) {
      // documentation : https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxScrollView/Configuration/
      scrollWidget.option('showScrollbar', 'always'); // On affiche la scrollbar si le contenu est "scrollable"
    }
  }

  ngAfterViewInit() {
    events.on(this.elementRef.nativeElement, 'dxclick', (e) => {
      this.openMenu.next(e);
    });

    const sub = this.router.events.pipe(
      filter(ev => ev instanceof NavigationEnd)
    ).subscribe((ev: NavigationEnd) => {
      const item = this.flattenItems.find(item => {
        if (!item.path) return false;
        const path = item.path.startsWith('/') ? item.path : '/' + item.path;
        return ev.urlAfterRedirects.startsWith(path);
      });
      if (item && this.menu?.instance) {
        const selectedNodes = this.menu.instance.getSelectedNodes();
        if (!selectedNodes.some(node => node.key === item.path)) {
          this.menu.instance.selectItem(item.path);
        }
      }
    });

    this.subscription.add(sub);
  }
  
  private getFlattenItems(items: Navigation[]) {
    return items.flatMap(i => i.items?.length > 0 ? [i].concat(this.getFlattenItems(i.items)) : [i]);
  }


  /** Region setNavigationAdministration */
  private setNavigationAdministrationConfig() {

    const config: Navigation[] = [];

    /* Filtre securite/roles, securite/utilisateurs, securite/affecter-roles */
    const currentUserCanManageUsers = this.authService.isGrantedWith(Permissions.canManageUsers);
    const currentUserCanManageRoles = this.authService.isGrantedWith(Permissions.canManageRoles);
    const currentUserCanAssignRoles = this.authService.isGrantedWith(Permissions.canAssignRoles);

    // On ajoute la sécurité dans le menu
    if (currentUserCanManageUsers || currentUserCanManageRoles || currentUserCanAssignRoles) {
      const securityConfigCopy = { ...this.navigationSecurityConfig };
      config.push(securityConfigCopy);
      
      // Exclure les onglets non-autorisés de la sécurité
      if (config.length > 0) {
        const lastItem = config[config.length - 1];

        // Seul root peut gérer les permissions des roles, on supprime par défaut
        this.removeNavigationItemByPath(lastItem.items, '/securite/roles');

        if (!currentUserCanManageUsers)
        this.removeNavigationItemByPath(lastItem.items, '/securite/utilisateurs');

        if (!currentUserCanAssignRoles)
        this.removeNavigationItemByPath(lastItem.items, '/securite/affecter-roles');
      }
    }

    /* Ajouter une autre entrée au menu de l'administration */
    // ...
  
    return config;
  }

  private removeNavigationItemByPath(items: Navigation[], pathToRemove: string): void {
    for (let i = items.length - 1; i >= 0; i--) {
      const item = items[i];
  
      if (item.path === pathToRemove) {
        // Si l'élément a le chemin spécifié
        // alors on le retire du tableau
        items.splice(i, 1);
      }
    }
  }
  /** Endregion setNavigationAdministration */

  
  ngOnDestroy() {
    events.off(this.elementRef.nativeElement, 'dxclick');
    this.subscription.unsubscribe();
  }
}

@NgModule({
  imports: [ DxTreeViewModule, FooterModule, RouterModule, CommonModule ],
  declarations: [ SideNavigationMenuComponent ],
  exports: [ SideNavigationMenuComponent ]
})
export class SideNavigationMenuModule { }
