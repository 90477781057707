import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NavigationCollaborateurMenuId, NavigationTop } from '../../models/navigation-top';
import { LxModule, LxModuleId, ParametrageRevatuaLexiClient, Permissions } from '@lexi-clients/lexi';
import { AuthService } from '../../settings/auth.service';

@Injectable()
export class ModuleService {
  public currentModule = new BehaviorSubject<number>(null);

  dicoLicencesModules: { idModule: LxModuleId; idMenu: NavigationCollaborateurMenuId; allowAccess: boolean }[] = [];

  constructor(
    private parametrageRevatuaLexiClient: ParametrageRevatuaLexiClient,
    private authService: AuthService
  ) {}

  set selectModule(moduleId: number) {
    this.currentModule.next(moduleId);
  }

  enableModulesActifs(modulesActifs: LxModule[], navigation: NavigationTop[]): NavigationTop[] {
    this.setDicoLicencesModules();
    const modulesActifsMenuIds = this.dicoLicencesModules.filter((menuAndModule) =>
      modulesActifs.some((module) => module.id === menuAndModule.idModule) && menuAndModule.allowAccess
    ).map(menuAndModule => menuAndModule.idMenu);
    
    // On affiche toujours l'onglet Comptabilite tant que l'utilisateur a la permission
    if (this.authService.isGrantedWith(Permissions.canGererEcrituresComptables)) {
      modulesActifsMenuIds.push(NavigationCollaborateurMenuId.comptabilite);
    }

    return navigation
      // Pour chaque NavigationTop, consèrve ceux étant associés à un Module Actif
      .filter(menuItem => modulesActifsMenuIds.some(menuId => menuId === menuItem.id))
      // Active tous ces NavigationTop sauf cas spécifique (eg. Fret Local)
      .map(menuItem => {

        const item = {
          id: menuItem.id,
          title: menuItem.title,
          enabled: true,
          navigation: menuItem.navigation,
        };

        return item;
      });
  }

  private setDicoLicencesModules() {
    this.dicoLicencesModules = [
      { idModule: LxModuleId.vente, idMenu: NavigationCollaborateurMenuId.vente, allowAccess: this.authService.isGrantedWith(Permissions.canAccederModuleVente)}, // Point de vente
      { idModule: LxModuleId.numera, idMenu: NavigationCollaborateurMenuId.logistique, allowAccess: this.authService.isGrantedWith(Permissions.canAccederModuleLogistique) }, // Logistique
      //{ idModule: LxModuleId.sav, idMenu: NavigationCollaborateurMenuId.sav }, // Sav
      //{ idModule: LxModuleId.natiraa, idMenu: NavigationCollaborateurMenuId.douane }, // Douane
      { idModule: LxModuleId.revatua, idMenu: NavigationCollaborateurMenuId.fretLocal, allowAccess: this.authService.isGrantedWith(Permissions.canAccederModuleFretLocal) }, // Fret local
      { idModule: LxModuleId.kpi, idMenu: NavigationCollaborateurMenuId.kpi, allowAccess: this.authService.isGrantedWith(Permissions.canAccederModuleKpi) }, // KPI
    ];
  }
}
