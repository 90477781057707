import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ArticlesLexiClient, ArticleForUpdateDto, ArticleFournisseurDto,
  ArticleType, ObjectType,
  SocieteToUpdateDto, Permissions, ComptesGenerauxLexiClient, CompteGeneralDto, StockageConnaissement, TarifRevatuaDto, ArticleInfosHeriteesDeLaClassification, TarifsRevatuaLexiClient
} from '@lexi-clients/lexi';
import notify from 'devextreme/ui/notify';
import { NotificationType } from '../../modules/shared/references/references';
import DataSource from 'devextreme/data/data_source';
import { CustomStoreService } from '../../services/custom-store.service';
import { PartenaireListService } from '../../services/partenaire.service';
import { IGridService } from '../../models/igrid-service';
import { DxFormComponent } from 'devextreme-angular';
import { TaxeGroupeListService } from '../../services/taxe-groupe.service';
import { ConditionnementListService } from '../../services/conditionnement-list.service';
import { UniteListService } from '../../services/unite.service';
import { FamilleListService } from '../../services/famille.service';
import { PaysListService } from '../../services/pays.service';
import { ArticleListService } from '../../services/article.service';
import { NomenclatureDouaneListService } from '../../services/nomenclature-douane.service';
import { SuffixeValeurListService } from '../../services/suffixe-valeur.service';
import { CodeExonerationListService } from '../../services/code-exoneration.service';
import { filter, lastValueFrom, Subscription } from 'rxjs';
import { AuthService } from '../../settings/auth.service';
import { SocieteService } from '../../services/societe.service';
import { ValidationResult } from 'devextreme/ui/validation_group';
import { ConfigFiltreMouvement } from '../../modules/shared/mouvement-stock-datagrid/mouvement-stock-datagrid.component';
import { DxDataSourceService } from '../../shared/services/dx-data-source.service';
import { ArticleDetailBonListComponent } from '../../modules/shared/article-detail-bon-list/article-detail-bon-list.component';
import { ConfigFiltreLot } from '../../modules/shared/lot-stockage-datagrid/lot-stockage-datagrid.component';

@Component({
  selector: 'app-article-detail',
  templateUrl: './article-detail.component.html',
  styleUrls: ['./article-detail.component.scss'],
})
export class ArticleDetailComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  readonly storedArticleToDuplicateKey = "ARTICLE_A_DUPLIQUER";
  readonly doRefreshKey = "FORCER_RELOAD_ARTICLE_DETAIL";
  @ViewChild('form') form: DxFormComponent;
  @ViewChild(ArticleDetailBonListComponent) articleDetailBonListRef: ArticleDetailBonListComponent;

  objectType = ObjectType;
  article: ArticleForUpdateDto;
  /** Contient les infos héritées d'une classification pour l'article en cours (dont comptes comptables, infos frêt, etc...) */
  infosHeriteesDeLaClassification: ArticleInfosHeriteesDeLaClassification;
  isModificationEnCours: boolean = false;
  isCreation: boolean = false;
  currentSiteId: number;
  currentPartenaireId: number;
  isInterfaceComptableActive: boolean = false;
  bonsHasLoaded = false;
  comptesGeneraux: CompteGeneralDto[];
  codeTarifRevatuaPlaceholder: string;
  stockageRevatuaPlaceholder: string;
  configFiltre = <ConfigFiltreMouvement>({
    isByArticle : true,
    isByPartenaire: true,
    isBySociete: false,
    isByDocumentLogistique: false,
    isByBon: false
  });
  configFiltreLot = <ConfigFiltreLot>({
    isByPartenaire: JSON.parse(sessionStorage.getItem("grille_lot_stockage_fiche_article_by_partenaire"))
  });

  readonly actions = [
    { id: 'edit', intitule: 'Modifier' },
    { id: 'dupliquer', intitule: 'Dupliquer' }
  ];

  readonly articleTypeDataSource: Array<{ id: ArticleType, libelle: string }> = [
    { id: ArticleType.backOffice , libelle: "BackOffice" },
    { id: ArticleType.commentaire , libelle: "Commentaire" },
    { id: ArticleType.metadata , libelle: "Metadata" },
    { id: ArticleType.kit , libelle: "Kit" },
    { id: ArticleType.prestation , libelle: "Prestation" },
  ];
  // Acces à l'enum côté html
  ArticleType = ArticleType;

  /** DataSource de la dataGrid de l'onglet 'Achats' */
  referencesFournisseurs: ArticleFournisseurDto[];

  // DataSources liées aux SelectBox
  uniteDataSource: DataSource;
  conditionnementDataSource: DataSource;
  taxeGroupeDataSource: DataSource;
  preparationZonesDataSource: DataSource;
  tarifRevatuaDataSource: TarifRevatuaDto[];

  // DataSources liées aux SelectBox de l'onglet 'Classifications'
  familleDataSource: DataSource;
  articleDataSource: DataSource;

  // DataSources liées aux SelectBox de l'onglet 'Achats'
  partenaireDataSource: DataSource;

  // DataSources liées aux SelectBox de l'onglet 'Douane'
  nomenclatureDouaneDataSource: DataSource;
  suffixeValeurDataSource: DataSource;
  codeExonerationDataSource: DataSource;
  paysDataSource: DataSource;

  isModificationAutorisee: boolean = false;
  canGererBonAchat: boolean = false;
  canModifierPrixRevient: boolean = false;
  showEditPrixRevientPopup: boolean = false;
  prixRevientAvantModification: number;
  stockageRevatuaList = [
    { id: StockageConnaissement.cale, intitule: "Cale" },
    { id: StockageConnaissement.refrigere, intitule: "Réfrigérée" },
    { id: StockageConnaissement.congele, intitule: "Congelé" },
    { id: StockageConnaissement.pontee, intitule: "Pontée" },
  ];

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly articlesLexiClient: ArticlesLexiClient,
    private readonly taxeGroupeService: TaxeGroupeListService,
    private readonly conditionnementService: ConditionnementListService,
    private readonly familleService: FamilleListService,
    private readonly uniteService: UniteListService,
    private readonly partenaireService: PartenaireListService,
    private readonly paysService: PaysListService,
    private readonly articleService: ArticleListService,
    private readonly tarifsRevatuaLexiClient: TarifsRevatuaLexiClient,
    private readonly nomenclatureDouaneService: NomenclatureDouaneListService,
    private readonly suffixeValeurService: SuffixeValeurListService,
    private readonly codeExonerationService: CodeExonerationListService,
    private readonly authService: AuthService,
    private readonly societeService: SocieteService,
    private readonly compteGenerauxLexiClient: ComptesGenerauxLexiClient,
    private readonly dxDataSourceService: DxDataSourceService
  ) { }

  async ngOnInit() {
    this.activatedRoute.params.subscribe(async params => {
      const articleId = params['id'];
      this.isModificationAutorisee = this.authService.isGrantedWith(Permissions.canModifierFicheArticle);
      this.canGererBonAchat = this.authService.isGrantedWith(Permissions.administrationBonsAchat);
      this.canModifierPrixRevient = this.authService.isGrantedWith(Permissions.canModifierPrixRevientArticle);

      // Création d'un nouvel article
      if (isNaN(Number(articleId))) {
        // Mauvais paramètre, on redirige sur la page de création d'un nouvel article
        if (articleId != 'nouveau') {
          await this.router.navigate(['/article/nouveau']);
          return;
        }
        this.isCreation = true;
        this.setNewArticle();
      }

      // Modification d'un site existant
      else {
        await this.setArticle(articleId);
        this.isCreation = false;
      }
      await this.loadEditors();
    });

    this.subscriptions.add(
      this.authService.currentSiteId$.pipe(
        filter((siteId: number) => siteId != null)
        ).subscribe((siteId: number) => {
          this.currentSiteId = siteId;
          this.currentPartenaireId = this.authService.currentSite.partenaireId;
      })
    );

    this.subscriptions.add(
      this.societeService.currentSociete$.pipe(
        filter((societe: SocieteToUpdateDto) => societe != null)
      ).subscribe(async (societe: SocieteToUpdateDto) => {
        this.isInterfaceComptableActive = societe.parametrageComptable?.interfaceActive ?? false;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async loadEditors() {
    this.taxeGroupeDataSource = this.dxDataSourceService.getDataSourceForSelectBox(this.taxeGroupeService);
    this.uniteDataSource = this.dxDataSourceService.getDataSourceForSelectBox(this.uniteService);
    this.tarifRevatuaDataSource = await lastValueFrom(this.tarifsRevatuaLexiClient.getAll());
    this.comptesGeneraux = await lastValueFrom(this.compteGenerauxLexiClient.getAll());

    if (!this.isCreation) {
      const conditionnementAdditionalParams = new Map().set("articleId", this.article?.id);
      this.conditionnementDataSource = this.dxDataSourceService.getDataSourceForSelectBox(this.conditionnementService, conditionnementAdditionalParams);
      await this.setReferencesFournisseurs();
    }
  }

  async setReferencesFournisseurs() {
    this.referencesFournisseurs = await lastValueFrom(this.articlesLexiClient.getReferencesFournisseurByArticleId(this.article?.id));
  }

  private setNewArticle() {
    if (localStorage.getItem(this.doRefreshKey)) {
      localStorage.removeItem(this.doRefreshKey);
      window.location.reload(); // Nécessaire pour rafraîchir la vue correctement (notamment le bouton "Créer")
      return;
    }

    const storedArticle = localStorage.getItem(this.storedArticleToDuplicateKey);
    if (storedArticle) {
      this.article = JSON.parse(storedArticle);
      delete this.article.id;
      this.article.codeBo = null;
      localStorage.removeItem(this.storedArticleToDuplicateKey);
    }
    else {
      this.article = {
        suiviSav: false,
        typeArticle: '',
        type: ArticleType.backOffice,
        bloque: false,
        quantiteMinimumVente: 1,
        rapportTarifaire: 1,
        rapportPhysique: 1,
        nombreNumeroSerie: 0,
        _synchronized: false,
        isPgc: false,
        isPpn: false,
      };
    }
  }

  private async setArticle(articleId: number) {
    this.article = await lastValueFrom(this.articlesLexiClient.getForUpdate(articleId));
    this.infosHeriteesDeLaClassification = await lastValueFrom(this.articlesLexiClient.getInfosHeriteesDeLaClassification(articleId));

    // Set placeholder CodeTarifRevatua
    if (this.article != null && this.infosHeriteesDeLaClassification?.codeTarifRevatua != null) {
      if (this.tarifRevatuaDataSource == null) this.tarifRevatuaDataSource = await lastValueFrom(this.tarifsRevatuaLexiClient.getAll());
      const tarifRevatua = this.tarifRevatuaDataSource.find(x => x.id == this.infosHeriteesDeLaClassification?.codeTarifRevatua);
      this.codeTarifRevatuaPlaceholder = this.codeTarifRevatuaDisplayExpr(tarifRevatua);
    }

    // Set placeholder StockageRevatua
    if (this.article != null && this.infosHeriteesDeLaClassification?.stockageRevatua != null) {
      const stockageRevatua = this.stockageRevatuaList.find(x => x.id == this.infosHeriteesDeLaClassification?.stockageRevatua);
      this.stockageRevatuaPlaceholder = stockageRevatua?.intitule;
    }
  }

  prixArticleEditorOptions: any = {
    icon: 'edit',
    onClick: () => this.showEditPrixRevientPopup = true,
  }

  opentPrixRevientPopup() {
    this.showEditPrixRevientPopup = true;
    this.prixRevientAvantModification = this.article.prixRevient;
  }
  
  async onUpdatePrixRevient() {
    await lastValueFrom(this.articlesLexiClient.updatePrixRevient(this.article.id, this.article.prixRevient));
    this.showEditPrixRevientPopup=false;
  }

  //#region Gestion des évènements
  onTabSelectionChanged = (e: {addedItems: Array<{title: 'Classifications' | 'Achats' | 'Douane' | string}>}) => {
    const title = e?.addedItems?.[0]?.title;
    switch (title) {
      case "Classifications":
        if (!this.familleDataSource) this.setTabClassificationsDataSources();
        break;
      case "Achats":
        if (!this.partenaireDataSource) this.setTabAchatsDataSources();
        break;
      case "Douane":
        if (!this.nomenclatureDouaneDataSource) this.setTabDouaneDataSources();
        break;
      case "Bons":
        if (!this.bonsHasLoaded){ 
          this.bonsHasLoaded = true;
          this.articleDetailBonListRef?.loadComponentData();
        }
        break;
      default:
        break;
    }
  }

  async onActionClick(e: any) {
    if( e.itemData.id == "edit") {
      this.isModificationEnCours = true;
    }

    else if( e.itemData.id == "dupliquer") {
      await this.onDuplicateArticle();
    }
  }

  private async onDuplicateArticle() {
    localStorage.setItem(this.doRefreshKey, "true");
    localStorage.setItem(this.storedArticleToDuplicateKey, JSON.stringify(this.article));
    await this.router.navigate(['/article/nouveau']);
  }

  async onCreateNewArticle() {
    // On empêche la création si les validations des comptes ne sont pas valides
    const formValidationResult: ValidationResult = this.form.instance.validate();
    if (!formValidationResult.isValid) return

    const newArticleId = await lastValueFrom(this.articlesLexiClient.createOrUpdate(this.article));
    notify({closeOnClick: true, message: "Article créé avec succès."}, NotificationType.Success);
    await this.router.navigate([`/article/${newArticleId}`]);
    window.location.reload();
  }

  async onUpdateArticle() {
    // On empêche la création si les validations des comptes ne sont pas valides
    const formValidationResult: ValidationResult = this.form.instance.validate();
    if (!formValidationResult.isValid) return

    await lastValueFrom(this.articlesLexiClient.createOrUpdate(this.article));
    this.isModificationEnCours = false;
    notify({closeOnClick: true, message: "Article modifié avec succès."}, NotificationType.Success);
  }

  async onCancel() {
    this.isModificationEnCours = false;
    await this.setArticle(this.article?.id);
  }
  //#endregion

  //#region Méthodes helpers
  private setTabClassificationsDataSources() {
    this.familleDataSource = this.dxDataSourceService.getDataSourceForSelectBox(this.familleService);
    this.articleDataSource = this.dxDataSourceService.getDataSourceForSelectBox(this.articleService);
  }

  private setTabAchatsDataSources() {
    const partenaireAdditionalParams = new Map().set("estClientOnly", false);
    this.partenaireDataSource = this.dxDataSourceService.getDataSourceForSelectBox(this.partenaireService, partenaireAdditionalParams);
  }

  private setTabDouaneDataSources() {
    this.nomenclatureDouaneDataSource = this.dxDataSourceService.getDataSourceForSelectBox(this.nomenclatureDouaneService);
    this.suffixeValeurDataSource = this.dxDataSourceService.getDataSourceForSelectBox(this.suffixeValeurService);
    this.codeExonerationDataSource = this.dxDataSourceService.getDataSourceForSelectBox(this.codeExonerationService);
    this.paysDataSource = this.dxDataSourceService.getDataSourceForSelectBox(this.paysService);
  }

  codeTarifRevatuaDisplayExpr(data: TarifRevatuaDto) {
    return data && `${data.code} - ${data.intitule}`;
  }
  //#endregion
}
