import { Injectable } from '@angular/core';
import { PersonnalisationGrilleDto, PersonnalisationGrillesLexiClient } from '@lexi-clients/lexi';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private readonly personnalisationGrilles: PersonnalisationGrillesLexiClient) { }

  /* Local Storage */
  // save dxGrid state on local storage
  public saveDxDataGridStateOnLocalStorage(state: object, storageKey: string): void {
    localStorage.setItem(storageKey, JSON.stringify(state));
  }

  // load dxGrid state from local storage
  public loadDxDataGridStateWithoutFilterValueFromLocalStorage(storageKey: string): object {
    let state: any = localStorage.getItem(storageKey);
    if (state) {
      state = JSON.parse(state);
      if (state.columns) {
        for (let i = 0; i < state.columns.length; i++) {
          state.columns[i].filterValue = null;
        }
      }
      state.filterValue = null;

    }
    return state;
  }

  /* Server Storage */
  // save dxGrid on server
  public async saveDxDataGridStateOnServer(state: string, storageKey: string): Promise<void> {
    const dto = {
      storageKey: storageKey,
      data: state
    };
    await lastValueFrom(this.personnalisationGrilles.createOrUpdate(dto));
  }

  // load dxGrid from server
  public async loadDxDataGridStateFromServer(storageKey: string) {
    const dto = {
      storageKey: storageKey,
    };
    const result: PersonnalisationGrilleDto = await lastValueFrom(this.personnalisationGrilles.getByStorageKey(storageKey));

    return result !== null ? JSON.parse(result.data) : null;
  }


  /* Local and Server Storage */
  // save dxGrid on local storage and server
  public async saveDxDataGridState(state: object, storageKey: string): Promise<void> {
    const stateString = JSON.stringify(state);
    localStorage.setItem(storageKey, stateString);
    
    await this.saveDxDataGridStateOnServer(stateString, storageKey);
  }

  // load dxGrid from local storage and from server
  public async loadDxDataGridState(storageKey: string): Promise<object> {
    let stateString: string = localStorage.getItem(storageKey);
  
    if (!stateString) {
      return await this.loadDxDataGridStateFromServer(storageKey);
    }
  
    return JSON.parse(stateString);
  }

  // load dxGrid from local storage and from server without filter
  public async loadDxDataGridStateWithoutFilterValue(storageKey: string): Promise<object> {
    let stateString: string = localStorage.getItem(storageKey);
  
    if (!stateString) {
      return await this.loadDxDataGridStateFromServer(storageKey);
    }
  
    const state = JSON.parse(stateString);
  
    if (state.columns) {
      state.columns.forEach(column => {
        column.filterValue = null;
      });
    }
    state.filterValue = null;
  
    return state;
  }
}